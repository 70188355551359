import React from 'react';

function About() {
  return (
    <section id="about" className="about">
      <div className="container">

        <div className="section-title">
          <h2>Quem Somos</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 order-1 order-lg-2">
            <img src={process.env.PUBLIC_URL+"img/about-img.jpg"} className="img-fluid" alt=""></img>
          </div>
          <div className="col-lg-8 pt-4 pt-lg-0 order-2 order-lg-1">
            <p>
              O pastor Dimas e Janis encontraram-se, pela primeira vez, quando faziam parte de um grupo musical chamado <a href="http://www.vpc.com.br/website/">"Vencedores por Cristo"</a> que, mais tarde, tornou-se uma Missão sediada em São Paulo. Eventualmente, casaram-se no dia 18 de dezembro de 1976 e tornaram-se missionários dessa Missão. Em seguida, após o pastor concluir seus estudos teológicos, foram morar em Curitiba-PR, onde durante 5 anos trabalharam na Igreja Batista do Prado.<br /><br />
              Em maio de 1993 estavam morando na Costa Oeste dos EUA, região de Los Angeles-CA, quando a Janis conseguiu um emprego como Comissária de Bordo da United Airlines. Após o treinamento, ela foi designada para ser baseada em New York NY, o que os levou de mudança para a Costa Leste do país (Newark-NJ), em  julho daquele ano.<br /><br />
              Dois meses depois, vieram à Philadelphia-PA para assistir uma apresentação de um grupo musical brasileiro chamado ... Vencedores por Cristo!!! ... que estaria numa igreja de brasileiros chamada ... (isso mesmo!).<br /><br />
              Naquela ocasião, ficaram sabendo que o custo de vida (aluguel de casa especialmente) era a metade do que estavam pagando em Newark. Ah, não deu outra! No mês seguinte foram de mudança para Philadelphia. Foi aí que ficaram sabendo que o então pastor da Igreja, Vagner Vaellati, estaria retornando ao Brasil no final daquele mesmo ano e, portanto, um substituto estava sendo procurado.<br /><br />
              Um convite oficial foi apresentado para que o pastor Dimas ocupasse a função de pastor a partir de janeiro de 1994, mas havia um problema: somente depois que o período de experiência da Janis encerrasse é que ela saberia, com certeza, que continuaria baseada em New York. Assim sendo, aceitaram "temporariamente" o convite.<br /><br />
              Esse "temporariamente" já tem durado mais de 14 anos, pela graça e misericórdia de Deus!            
            </p>
          </div>
        </div>

      </div>
    </section>
  );
}

export default About;