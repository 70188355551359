import React from 'react';

function Contact() {
  const styleObj = {
    border: 0
  };
  
  return (
    <div>
      <section id="contact" className="contact section-bg">
      <div className="container">

        <div className="section-title">
          <h3>Entre em contato</h3>
          <h2>Nossos encontros são aos domingos começando às 18:30</h2>
        </div>

        <div className="row">

          <div className="col-lg-6 col-md-6">
            <div className="contact-about">
              <h3>Vida Nova</h3>
              <p>Eu lhes asseguro: Quem ouve a minha palavra e crê naquele que me enviou, tem a vida eterna e não será condenado, mas já passou da morte para a vida. (João 5:24)</p>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="info">
              <div>
                <i className="bi bi-geo-alt"></i>
                <p>1717 Street Rd<br/>
                Southampton, PA 18966</p>
              </div>

              <div>
                <i className="bi bi-envelope"></i>
                <p>igrejabrasileiraBBC@gmail.com</p>
              </div>

              <div>
                <i className="bi bi-phone"></i>
                <p>215-816-8181</p>
              </div>

            </div>
          </div>
        </div>

      </div>
      </section>
      <section className="map">
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1524.571827274954!2d-75.02110955640417!3d40.16135516111309!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6ada1f6d1a0df%3A0x6a6adcbd8bd22d3c!2s1717%20Street%20Rd%2C%20Southampton%2C%20PA%2018966!5e0!3m2!1sen!2sus!4v1635283491757!5m2!1sen!2sus" width="600" height="450" style={styleObj} allowFullScreen loading="lazy"></iframe>
      </section>
    </div>
  );
}

export default Contact;