import React from 'react';

function Hero() {
  return (
    <section id="hero">
    <div className="hero-container">
      <h1>Bem vindo a Igreja Vida Nova</h1>
      <h2>Igreja Batista Brasileira</h2>
      <a href="#about" className="btn-get-started scrollto">Quem Somos</a>
    </div>
  </section>
  );
}

export default Hero;