import React from 'react';

function Footer() {
  return (
    <footer id="footer">
    <div className="container">
      <div className="copyright">
        &copy; Copyright <strong><span>Vida Nova Brazilian Baptist Church</span></strong>. All Rights Reserved
      </div>
    </div>
  </footer>
  );
}

export default Footer;